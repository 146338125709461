<template>
  <FullScreenModal title="選擇單品" @back="backClicked">
    <div class="cloth-picker">

      <div class="py-4" v-show="parseCloths.length === 0">
        <p class="text-center">沒有可選擇的單品，請至衣櫥中新增單品</p>
        <div class="text-center">
          <button type="button" class="no-cloth" name="button" @click="backClicked">取消選擇</button>
        </div>
      </div>

      <div v-if="parseCloths.length > 0">

        <section class="header-tools">
          <div class="container">
            <div class="row">
              <div class="col">
                <button class="horizon-btn" :disabled="noSelection" @click="okClicked">
                  確定
                </button>
              </div>
            </div>
          </div>
        </section>

        <div class="container">
          <section class="category-list" v-if="init">

            <div class="category-cloths" v-for="(item) in parseCloths" :key="item.category.serial">
              <div class="row justify-content-between align-items-center no-gutters">
                <p class="cs-name">{{item.category.name}}</p>
              </div>

              <div class="d-flex align-items-center cloth-count">
                <img :src="require('@/assets/icons/cloth-icon.png')" style="width: 14px; display: block; margin-right: .25rem;"/>
                <span>{{item.cloths.length}}件</span>
              </div>

              <div class="row">
                <div class="col-4 position-relative mb-3" v-for="(cloth) in item.cloths" :key="cloth.serial">
                  <div class="" @click="clothClicked(cloth.serial)">
                    <ClothBlock :cloth="cloth" :categories="clothCategories" showCheck :checked="selectedItems.includes(cloth.serial)">
                    </ClothBlock>
                  </div>
                </div>
              </div>
            </div>

          </section>

        </div>

      </div>


    </div>
  </FullScreenModal>
</template>


<script>
import ClothBlock from '@/components/ClothBlock.vue';
import FullScreenModal from '@/components/FullScreenModal.vue';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'SingleClothPicker',
  data() {
    return {
      init: false,

      parseCloths: [],
      selectedItems: [],

    };
  },
  created() {
  },
  props: {
    except: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    limit: {
      type: Number,
      required: false,
      default: 1,
    },
  },
  components: {
    ClothBlock,
    FullScreenModal
  },
  computed: {
    ...mapState(['cloths', 'clothCategories']),
    noSelection() {
      return this.selectedItems.length === 0;
    },
	},
  beforeDestroy() {
  },
  watch: {
  },
  mounted() {
    this.parseData();
    this.init = true;
	},
  methods: {
    ...mapActions(['showMsgModal', 'appendErrorMsg']),
    parseData() {
      this.parseCloths.splice(0, this.parseCloths.length);
      for (const cs of this.clothCategories) {

        const cloths = this.cloths.filter((c) => {
          return !c.isDeleted && c.recycleType === -1 && c.category1 === cs.serial && this.except.indexOf(c.serial) === -1;
        });

        if (cloths.length > 0) {
          this.parseCloths.push({
            category: cs,
            cloths,
          });
        }
      }
    },
    clothClicked(serial) {
      if (this.selectedItems.includes(serial)) {
        this.selectedItems.splice(this.selectedItems.indexOf(serial), 1);
      } else {
        if (this.selectedItems.length < this.limit) {
          this.selectedItems.push(serial);
        } else {
          this.showMsgModal('超過可選取的上限');
        }
      }
    },
    backClicked() {
      this.$emit('back');
    },
    okClicked() {
      this.$emit('ok', this.selectedItems);
    },
    emitError(err) {
      this.appendErrorMsg(err);
    },
  }
}
</script>

<style scoped>
  .cloth-picker {
  }

  .no-cloth {
    font-size: 12px;
    border: none;
    background-color: #C4C4C4;
    padding: .8em 1.5em;
    border-radius: .5rem;
  }

  .header-tools {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2;
    background-color: #eeeeee;
    padding: 1rem 0;
  }

  .header-tool-btn {
    display: block;
    background-color: #fff;
    font-size: 12px;
    color: var(--text-dark);
    padding: .5rem;
  }

  .header-tool-btn:hover {
    text-decoration: none;
  }

  .header-tool-btn>img {
    width: 18px;
    height: 18px;
    object-fit: contain;
    display: block;
    margin-right: .5rem;
  }

  .category-list {

  }

  .category-cloths {
    padding: 1.5rem 0 .5rem;
  }

  .category-cloths:not(:first-child) {
    border-top: solid 1px #C4C4C4;
  }

  .cs-name {
    font-size: 18px;
    font-weight: 600;
    color: var(--text-dark);
    margin: 0;
  }

  .cloth-count {
    font-size: 12px;
    color: var(--text-light);
    margin-bottom: .5rem;
  }
</style>
