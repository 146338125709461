<template>
  <div class="delivery-card">
    <div class="d-card-title">
      <div class="title-logo">
        <span class="img-container">
          <img :src="require(`@/assets/logos/walkingcloset.png`)"/>
        </span>
      </div>

      <div class="title-text">
        {{ businessAccountName }}
      </div>
    </div>
    <div class="d-card-desc">
      <p v-if="isWaitingSubmit">需於 {{ submitTimeLimitStr }} 前送出資料</p>
      <p v-if="isWaitingQuotation">最晚於 {{ quotationTimeLimitStr }} 前回覆審查結果</p>
      <p v-if="isWaitingConfirm">需於 {{ confirmTimeLimitStr }} 前回覆</p>
      <!-- 暫時隱藏 -->
      <!-- <p v-if="isWaitingSend">需於 {{ sendClothTimeLimitStr }} 前寄出</p> -->
      <p v-if="isClothSent || isClothReceived">於 {{ sendClothTimeStr }} 完成服務</p>
    </div>
    
    <hr/>

    <div>
      <div class="d-card-desc-2" v-if="isWaitingSubmit">
        <div>
          上限 {{ submit.maxAmount }} 件
        </div>

        <div>
          加購金額 NTD {{ submit.price }}
        </div>
      </div>

      <div class="d-card-desc-2" v-if="isWaitingQuotation || isWaitingConfirm">
        <div>
          已送出 {{ submit.amount }} 件
        </div>
      </div>

      
      <div class="d-card-desc-2" v-if="isWaitingSend">
        <div>
          待動作 {{ submit.confirmedAmount }} 件
        </div>
      </div>

      <div class="d-card-desc-2" v-if="isClothSent || isClothReceived">
        <div>
          寄出 {{ submit.confirmedAmount }} 件
        </div>
      </div>
    </div>

    <div class="text-right mt-2">
      <button class="btn btn-submit" v-if="isWaitingSubmit" @click="submitBtnClicked">填寫衣物資料</button>

      <button class="btn btn-submit mr-2" v-if="isWaitingQuotation" @click="showContactModal">聯絡 {{ businessAccountName }}</button>
      <button class="btn btn-submit" v-if="isWaitingQuotation" @click="viewSubmitClicked">訂單資料</button>
      <button class="btn btn-submit" v-if="isWaitingConfirm" @click="viewConfirmClicked">查看/確認</button>
      <button class="btn btn-submit" v-if="isWaitingSend" @click="viewSendClothClicked">查看</button>
      <button class="btn btn-submit" v-if="isClothSent || isClothReceived" @click="viewClothsClicked">查看詳細資料</button>
    </div>

    <transition name="fade">
      <WcCommonModal :title="`聯絡 ${businessAccountName}`" v-if="isContactModalShow" @ok="isContactModalShow = false" hide-header hide-cancel>
        <div class="">
          聯絡{{ businessAccountName }}<br/>
          <br/>
          若有訂單問題歡迎聯絡 {{ businessAccountName }}<br/>
          客服電話 ：02-7752-2510<br/>
          客服時間 / 一至五（除國定假日） 10:00-17:00<br/>
          客服 LINE ID：@walkingcloset<br/>
          <br/>
          統編 ：82975471<br/>
          人人共創數位科技有限公司
        </div>
      </WcCommonModal>
    </transition>
  </div>
</template>

<script>
// @ is an alias to /src
// import { mapState } from 'vuex';
import moment from 'moment';
import WcCommonModal from '@/components/commons/WcCommonModal.vue';

export default {
  name: 'DeliveryCard',
  data() {
    return {
      businessAccountName: '長腳衣櫥',
      isContactModalShow: false,
    };
  },
  components: {
    WcCommonModal,
  },
  props: {
    submit: {
      type: Object,
      required: true,
    }
  },
  beforeDestroy() {
  },
  computed: {
    isWaitingSubmit() {
      return this.submit.status === 0;
    },
    isWaitingQuotation() {
      return this.submit.status === 1;
    },
    isWaitingConfirm() {
      return this.submit.status === 2;
    },
    isWaitingSend() {
      return this.submit.status === 3;
    },
    isClothSent() {
      return this.submit.status === 4;
    },
    isClothReceived() {
      return this.submit.status === 5;
    },
    submitTimeLimitStr() {
      return moment(this.submit.submitTimeLimit).format('YYYY.MM.DD HH:mm');
    },
    quotationTimeLimitStr() {
      return moment(this.submit.quotationTimeLimit).format('YYYY.MM.DD HH:mm');
    },
    confirmTimeLimitStr() {
      return moment(this.submit.confirmTimeLimit).format('YYYY.MM.DD HH:mm');
    },
    sendClothTimeLimitStr() {
      return moment(this.submit.sendClothTimeLimit).format('YYYY.MM.DD HH:mm');
    },
    sendClothTimeStr() {
      return moment(this.submit.sendClothNoticeTime).format('YYYY.MM.DD HH:mm');
    }
  },
  watch: {
  },
  mounted() {
  },
  methods: {
    submitBtnClicked() {
      this.$emit('submit');
    },
    showContactModal() {
      this.isContactModalShow = true;
    },
    viewSubmitClicked() {
      this.$emit('view');
    },
    viewConfirmClicked() {
      this.$emit('confirm');
    },
    viewSendClothClicked() {
      this.$emit('viewSendCloth');
    },
    viewClothsClicked() {
      this.$emit('view');
    },
  }
}
</script>

<style scoped>
  .delivery-card {
  }

  .d-card-title {
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: .5rem;
  }

  .title-logo {
    flex: 0 0 auto;
    padding-right: .8rem;
  }

  .img-container {
    position: relative;
    display: block;
    width: 32px;
    height: 32px;
    border-radius: 10%;
    overflow: hidden;
  }

  .img-container>img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .fake-img {
    display: block;
    width: 32px;
    height: 32px;
    border-radius: 10%;
    background-color: #ddd;
  }

  .title-text {
    flex: 1 1;
  }

  .d-card-desc {
    margin-bottom: .5rem;
  }

  hr {
    margin: 0 0 .5rem;
  }

  .d-card-desc-2 {
    display: flex;
    justify-content: space-between;
    margin-bottom: .5rem;
  }

  .btn.btn-submit {
    background-color: #4D4D4D;
    border-radius: 6px;
    color: #fff;
    padding: 6px 1rem;
  }
</style>
  