<template>
  <div class="selected-cloth" :class="{'failed': !clothPassed}">
    <div class="delete-btn" @click="deleteBtnClicked" v-if="isSubmitting">
      <span class="minus">－</span>
    </div>

    <div class="delete-btn" style="opacity:0" v-if="isWaiting || isWaitingSend">
      <span>－</span>
    </div>

    <div class="delete-btn" :class="{'white-bg': review.pass === 2}" @click="toggleAgreeClicked" v-if="isWaitingConfirm">
      <span v-if="review.pass === 2">
        無<br/>法<br/>寄<br/>售
      </span>
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="review.pass === 1 && review.agree !== 2">
        <line x1="4" y1="4" x2="10" y2="10" stroke="#4D4D4D" stroke-width="1.5" stroke-linecap="round"/>
        <line x1="10" y1="4" x2="4" y2="10" stroke="#4D4D4D" stroke-width="1.5" stroke-linecap="round"/>
      </svg>
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="review.pass === 1 && review.agree === 2">
        <circle cx="7" cy="7" r="7.5" fill="#444"/>
        <line x1="4" y1="4" x2="10" y2="10" stroke="#fff" stroke-width="1.5" stroke-linecap="round"/>
        <line x1="10" y1="4" x2="4" y2="10" stroke="#fff" stroke-width="1.5" stroke-linecap="round"/>
      </svg>
    </div>

    <div class="delete-btn" v-if="isClothSent || isClothReceived">
      <span v-if="review.pass === 2">
        無<br/>法<br/>寄<br/>售
      </span>
      <span v-if="review.pass === 1">
        <span v-if="review.agree === 1">
          <span v-if="review.status === 2">
            尚<br/>未<br/>收<br/>到
          </span>
          <span v-if="review.status === 3">
            已<br/>收<br/>件
          </span>
        </span>
        <span v-if="review.agree === 2" style="opacity:0">
          <span>－</span>
        </span>

      </span>
    </div>

    <div class="cloth-img">
      <img :src="clothImg"/>
    </div>

    <div class="cloth-info">
      <div class="cloth-info-grid">
        <div class="title">類別</div>
        <div>{{category1Name}} / {{category2Name}}</div>
        <div class="title">色系</div>
        <div><ColorSelect v-model="cloth.color" :selection="false"></ColorSelect></div>
        <!-- <div class="title"> {{ ((isSubmitting || isWaiting) ? '期待定價': '預期定價') }} </div> -->
        <div class="title"> {{ ((isSubmitting || isWaiting) ? '衣物原價': '衣物原價') }} </div>
        <div v-if="isSubmitting"><input type="number" inputmode="numeric" class="wc-form-dom" :class="{'hilight': needInputPrice}" style="width:80px;" v-model="clothExpectedPrice"/> 元</div>
        <div v-if="!isSubmitting">{{ review.finalSellPrice === -1 ? review.sellPrice : review.finalSellPrice }} 元</div>
      </div>
    </div>

    <div class="cloth-edit-btn" :class="{'hilight': needEdit}" @click="openClothEdit" v-if="isSubmitting">
      編<br/>
      輯
    </div>

    <div class="cloth-edit-btn" style="opacity: 0;" v-if="isWaiting || isWaitingSend">
      編<br/>
      輯
    </div>

    <div class="cloth-edit-btn" v-if="isWaitingConfirm && review.pass === 2" @click="failedReasonModalShow = true">
      <span>查<br/>看<br/>原<br/>因</span>
    </div>

    <div class="cloth-edit-btn" v-if="isWaitingConfirm && review.agree === 2">
      <span>不<br/>同<br/>意</span>
    </div>



    <div class="cloth-edit-btn" v-if="(isClothSent || isClothReceived) && review.pass === 2" @click="failedReasonModalShow = true">
      <span>查<br/>看<br/>原<br/>因</span>
    </div>

    <div class="cloth-edit-btn" v-if="(isClothSent || isClothReceived) && review.agree === 2">
      <span>不<br/>同<br/>意</span>
    </div>

    <div class="cloth-edit-btn" v-if="(isClothSent || isClothReceived) && review.agree === 1">
      <span>已<br/>寄<br/>出</span>
    </div>



    <transition name="fade">
      <CreateCloth v-if="showEditCloth" @updated="clothUpdated" :id="cloth.serial" @back="editClothCancel"></CreateCloth>
    </transition>

    <transition name="fade">
      <WcCommonModal v-if="failedReasonModalShow" @ok="failedReasonModalShow = false" hide-header hide-cancel>
        <div class="">
          無法寄售原因<br/>
          <ul>
            <li>{{ review.rejectReason }}</li>
          </ul>
          若有訂單問題歡迎聯絡 80percent<br/>
          <ul>
            <li>客服電話 ：0983-621-761</li>
            <li>客服時間 / 一至五（除國定假日） 10:00-17:00</li>
            <li>客服信箱：80percent.cs@gmail.com</li>
          </ul>
        </div>
      </WcCommonModal>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import ColorSelect from '@/components/closet/ColorSelect.vue';
import WcCommonModal from '@/components/commons/WcCommonModal.vue';
import CreateCloth from '../closet/CreateCloth.vue';
import '@/assets/css/form-inputs.css';
// import moment from 'moment';

export default {
  name: 'DeliverySubmitReview',
  data() {
    return {
      inputPrice: 0,
      showEditCloth: false,
      failedReasonModalShow: false,
    };
  },
  components: {
    ColorSelect,
    CreateCloth,
    WcCommonModal,
  },
  props: {
    review: {
      type: Object,
      required: true,
    },
    submitStatus: {
      type: Number,
      required: true,
    }
  },
  created() {
    this.inputPrice = this.review.sellPrice;
  },
  beforeDestroy() {
  },
  mounted() {
    
  },
  computed: {
    ...mapState(['clothColors', 'clothCategories']),
    cloth() {
      return this.review.cloth;
    },
    clothImg() {
      return this.cloth.imgs[0];
    },
    category1() {
      const res = this.clothCategories.filter((item) => {
        return this.cloth.category1 === item.serial;
      });
      return res[0];
    },
    category1Name() {
      return this.category1.name;
    },
    category2Name() {
      const res = this.category1.sub.filter((item) => {
        return this.cloth.category2 === item.serial;
      });
      if (res.length > 0) {
        return res[0].name;
      }
      return '';
    },
    isSubmitting() {
      return this.submitStatus === 0;
    },
    isWaiting() {
      return this.submitStatus === 1;
    },
    isWaitingConfirm() {
      return this.submitStatus === 2;
    },
    isWaitingSend() {
      return this.submitStatus === 3;
    },
    isClothSent() {
      return this.submitStatus === 4;
    },
    isClothReceived() {
      return this.submitStatus === 5;
    },
    clothPassed() {
      if (this.isSubmitting) {
        if (this.cloth.imgs.length === 0) {
          return false;
        }
        if (this.cloth.photoBack.length === 0) {
          return false;
        }
        if (this.cloth.photoBrand.length === 0) {
          return false;
        }
        if (this.cloth.photoWash.length === 0) {
          return false;
        }
        if (this.clothExpectedPrice <= 0) {
          return false;
        }
      } else if (this.isWaitingConfirm) {
        if (this.review.pass === 2) {
          return false;
        }
        if (this.review.agree === 2) {
          return false;
        }
      } else if (this.isClothSent || this.isClothReceived) {
        return !(this.review.pass === 2 || this.review.agree === 2);
      }
      return true;
    },
    needEdit() {
      if (this.cloth.imgs.length === 0) {
        return true;
      }
      if (this.cloth.photoBack.length === 0) {
        return true;
      }
      if (this.cloth.photoBrand.length === 0) {
        return true;
      }
      if (this.cloth.photoWash.length === 0) {
        return true;
      }
      return false;
    },
    needInputPrice() {
      if (this.clothExpectedPrice <= 0) {
        return true;
      }
      return false;
    },
    clothExpectedPrice: {
      get() {
        return this.inputPrice;
      },
      set(value) {
        const intPrice = parseInt(value);
        if (isNaN(intPrice)) {
          this.inputPrice = 0;
          this.$emit('updatePrice', {
            cloth: this.cloth,
            price: 0,
          });
        } else {
          this.inputPrice = intPrice;
          this.$emit('updatePrice', {
            cloth: this.cloth,
            price: intPrice,
          });
        }
      },
    },
  },
  watch: {
  },
  methods: {
    ...mapActions(['updateCloth']),
    deleteBtnClicked() {
      this.$emit('delete', this.cloth);
    },
    toggleAgreeClicked() {
      this.$emit('toggleAgree', this.review);
    },
    openClothEdit() {
      this.showEditCloth = true;
    },
    clothUpdated(cloth) {
      // console.log(cloth);
      this.showEditCloth = false;
      this.updateCloth(cloth);
      this.$emit('updateCloth', cloth);
    },
    editClothCancel() {
      this.showEditCloth = false;
    },
  }
}
</script>

<style scoped>
  .selected-cloth {
    display: flex;
    align-items: center;
    min-height: 110px;
  }

  .selected-cloth.failed {
    background-color: #E2E2E2;
  }

  .delete-btn {
    display: block;
    flex: 0 0 auto;
    padding: .6rem .6rem;
  }

  .delete-btn>span.minus {
    display: flex;
    width: 1rem;
    height: 1rem;
    background-color: #f00;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    color: #fff;
  }

  .delete-btn.white-bg {
    background-color: #EEEEEE;
  }

  .cloth-img {
    flex: 0 0 auto;
    padding-left: .25rem;
  }

  .cloth-img>img {
    width: 80px;
    height: 80px;
    border-radius: 1rem;
    background-color: #EEEEEE;
    object-fit: contain;
  }

  .cloth-info {
    flex: 1 1;
  }

  .cloth-info-grid {
    padding-left: .5rem;
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 0.8rem;
    row-gap: 0.2rem;
  }

  .selected-cloth.failed .cloth-info-grid {
    color: #c0c0c0;
  }

  .cloth-info-grid .title {
    font-weight: 1000;
    white-space: nowrap;
    display: flex;
    align-items: center;
  }

  .cloth-edit-btn {
    flex: 0 0 auto;
    background-color: #EEE;
    color: #4D4D4D;
    align-self: stretch;
    display: flex;
    align-items: center;
    padding: 0 .8rem;
    min-height: 100px;
  }

  .cloth-edit-btn.hilight {
    background-color: var(--main-orange);
    color: #fff;
  }

  .wc-form-dom.hilight {
    outline: solid 2px #f00;
  }
</style>
