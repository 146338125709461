<template>
  <div class="delivery-item-list">
    <div class="container">
      
      <div>

        <div class="delivery-item">
          <div class="item-title">
            <h3>待送出衣物資料</h3>
          </div>

          <div class="item-content" v-for="(item) in status0List" :key="item.serial">
            <DeliveryCard :submit="item" @submit="openSubmitModal(item)"></DeliveryCard>
          </div>

          <div class="item-content" v-if="status0List.length === 0">尚無資料</div>

        </div>

        <div class="delivery-item">
          <div class="item-title">
            <h3>等待審核</h3>
          </div>

          <div class="item-content" v-for="(item) in status1List" :key="item.serial">
            <DeliveryCard :submit="item" @view="openSubmitModal(item)"></DeliveryCard>
          </div>
          <div class="item-content" v-if="status1List.length === 0">尚無資料</div>

        </div>

        <div class="delivery-item">
          <div class="item-title">
            <h3>審核結果確認</h3>
          </div>

          <div class="item-content" v-for="(item) in status2List" :key="item.serial">
            <DeliveryCard :submit="item" @confirm="openSubmitModal(item)"></DeliveryCard>
          </div>
          <div class="item-content" v-if="status2List.length === 0">尚無資料</div>

        </div>

        <div class="delivery-item">
          <div class="item-title">
            <h3>等待後續動作</h3>
          </div>

          <div class="item-content" v-for="(item) in status3List" :key="item.serial">
            <DeliveryCard :submit="item" @viewSendCloth="openSubmitModal(item)"></DeliveryCard>
          </div>
          <div class="item-content" v-if="status3List.length === 0">尚無資料</div>

        </div>

        <div class="delivery-item">
          <div class="item-title">
            <h3>歷史訂單</h3>
          </div>

          <div class="item-content" v-for="(item) in historyList" :key="item.serial">
            <DeliveryCard :submit="item" @view="openSubmitModal(item)"></DeliveryCard>
          </div>
          <div class="item-content" v-if="historyList.length === 0">尚無資料</div>

        </div>

        <!-- <div class="delivery-item">
          <div class="item-title">
            <h3>取回衣物</h3>
          </div>

          <div class="item-content" v-for="(item) in status5List" :key="item.serial">
            <DeliveryCard :submit="item"></DeliveryCard>
          </div>
          <div class="item-content" v-if="status5List.length === 0">尚無資料</div>

        </div> -->

      </div>

    </div>

    <transition name="fade">
      <DeliverySubmitModal v-if="isSubmitModalShow" :id="actionSubmitSerial" @close="closeSubmitModal" @updated="clothsSubmitted"></DeliverySubmitModal>
    </transition>
  </div>
</template>


<script>
import { mapActions } from 'vuex';
import DeliveryCard from '@/views/delivery/DeliveryCard.vue';
import DeliverySubmitModal from '@/views/delivery/DeliverySubmitModal.vue';

export default {
  name: 'DeliveryItemList',
  data() {
    return {
      readingBusyName: 'READINGSNIPPESTDATA',
      
      deliveryList: [],
      actionSubmitSerial: -1,

      isSubmitModalShow: false,
    };
  },
  components: {
    DeliveryCard,
    DeliverySubmitModal,
  },
  props: {
  },
  beforeDestroy() {
    this.clearComponentBusy(this.readingBusyName);
  },
  mounted() {
    this.refresh();
  },
  computed: {
    status0List() {
      return this.deliveryList.filter((item) => {
        return item.status === 0;
      }).sort((a, b) => {
        return b.serial - a.serial;
      });
    },
    status1List() {
      return this.deliveryList.filter((item) => {
        return item.status === 1;
      }).sort((a, b) => {
        return b.serial - a.serial;
      });
    },
    status2List() {
      return this.deliveryList.filter((item) => {
        return item.status === 2;
      }).sort((a, b) => {
        return b.serial - a.serial;
      });
    },
    status3List() {
      return this.deliveryList.filter((item) => {
        return item.status === 3;
      }).sort((a, b) => {
        return b.serial - a.serial;
      });
    },
    historyList() {
      return this.deliveryList.filter((item) => {
        return item.status === 4 || item.status === 5;
      }).sort((a, b) => {
        return b.serial - a.serial;
      });
    },
    status5List() {
      return this.deliveryList.filter((item) => {
        return item.status === 5;
      }).sort((a, b) => {
        return b.serial - a.serial;
      });
    },
  },
  watch: {
  },
  methods: {
    ...mapActions(['showMsgModal', 'setBodyConfigShow', 'appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg']),
    async refresh() {
      this.appendComponentBusy(this.saveBusyName);
      try {
        const list = await this.$store.dispatch('api/readDeliveryListPromise');
        this.deliveryList.splice(0, this.deliveryList.length);
        for (const data of list) {
          this.deliveryList.push(data);
        }
      } catch(e) {
        this.emitError(e);
      } finally {
        this.clearComponentBusy(this.saveBusyName);
      }
    },
    openSubmitModal(submit) {
      this.actionSubmitSerial = submit.serial;
      this.isSubmitModalShow = true;
    },
    closeSubmitModal() {
      this.isSubmitModalShow = false;
    },
    clothsSubmitted(submit) {
      console.log(submit);
      this.closeSubmitModal();
      this.refresh();
    },
  }
}
</script>

<style scoped>
  .delivery-item-list {
    padding: .5rem 0;
  }

  .delivery-item {
    margin-bottom: 1rem;
  }

  .item-title {
    border-radius: .5rem;
    background-color: #f8f8f8;
    padding: .8rem 1.2rem;
    margin-bottom: .25rem;
  }

  .item-title>h3 {
    margin: 0;
    color: #4d4d4d;
    font-weight: 1000;
    font-size: 1rem;
  }

  .item-content {
    border-radius: .5rem;
    border: solid 1px #DDD;
    padding: .8rem 1.2rem;
    margin-bottom: .5rem;
  }

</style>
