<template>
  <FullScreenModal title="衣物審核資料" @back="backClicked">
    <div class="delivery-submit pt-3">

      <div class="container" v-if="init">
        <div class="flow-desc">
          <div class="flow-intro">
            <h3>訂單資訊</h3>
          </div>

          <div class="flow-intro">
            <p class="mb-3">
              <span class="font-weight-bold">訂單編號：</span>{{ submit.id }}
            </p>
            <p class="mb-3">
              <span class="font-weight-bold">服務提供：</span><br/>
              {{ businessAccountName }}（人人共創數位科技有限公司）
            </p>
            <p class="mb-3">
              <span class="font-weight-bold">服務內容：</span><br/>
              共享衣櫥：夏日換衣派對<br/>
              9/16-17 換衣資格申請（限購買1週內啟用）<br/>
              可於活動兩日帶著符合資格的衣物至華山文創園區 ACT NOW 永續行動嘉年華 - 長腳衣櫥攤位換衣
            </p>
            <p class="mb-3">
              <span class="font-weight-bold">衣物上限：</span>{{ submit.maxAmount }} 件
            </p>
            <p class="mb-3">
              <span class="font-weight-bold">服務加購金額：</span>NTD {{ submit.price }}
            </p>
            <p v-if="isSubmitting">
              需於 {{ submitLimitTimeStr }} 前送出資料
            </p>
            <p v-if="submit.status > 0">
              已於 {{ __dateToString(submit.submitTime) }} 送出資料
            </p>
            <p v-if="submit.status > 1">
              {{ businessAccountName }} 於 {{ __dateToString(submit.quotationTime) }} 審核
            </p>
            <p v-if="submit.status > 2">
              已於 {{ __dateToString(submit.confirmTime) }} 確認審核結果
            </p>
            <p v-if="submit.status > 3">
              已於 {{ __dateToString(submit.sendClothNoticeTime) }} 回報完成後續動作
            </p>

            <p v-if="submit.status > 3" class="mt-2"> 
              最後更新時間 {{ __dateToString(submit.updated_at) }}
            </p>
          </div>
        </div>

        <div class="flow-desc">
          <div class="flow-intro">
            <h3>聯絡資料</h3>
          </div>

          <div class="flow-intro" v-if="isSubmitting">
            <div class="mb-4">
              <label>姓名<span class="text-orange">*</span></label>
              <input type="text" class="wc-form-dom w-100 mb-2" :class="{'hilight': !inputNameValid}" v-model="inputName"/>
            </div>
            
            <div class="mb-4">
              <label>連絡電話<span class="text-orange">*</span></label>
              <input type="text" class="wc-form-dom w-100 mb-2" :class="{'hilight': !inputPhoneValid}" v-model="inputPhone"/>
            </div>

            <div>
              <label>電子郵件<span class="text-orange">*</span></label>
              <input type="email" class="wc-form-dom w-100 mb-2" :class="{'hilight': !inputEmailValid}" v-model="inputEmail"/>
            </div>
          </div>

          <div class="flow-intro" v-if="!isSubmitting">
            <p>{{ submit.userName }}</p>
            <p>{{ submit.userPhone }}</p>
            <p>{{ submit.userEmail }}</p>
          </div>
        </div>

        <div class="flow-desc" v-if="isSubmitting">
          <div class="flow-intro">
            <h3>選擇衣物</h3>
          </div>

          <div class="flow-intro">
            <ul class="ml-0 pl-4">
              <li>至多選擇 {{ submit.maxAmount }} 件衣物</li>
              <li>每件衣物皆需提供 4 張照片（衣物正面、衣物背面、衣領品牌標、洗標）</li>
            </ul>
          </div>
        </div>

        <div v-if="isSubmitting">
          <button class="btn btn-sm btn-block btn-select text-center mb-3" @click="openClothPicker" :disabled="canPickAmount <= 0">選取衣物 ( {{ reviews.length }} / {{ submit.maxAmount }})</button>
        </div>
        
        <div v-if="isWaiting || isClothSent || isClothReceived">
          <button class="btn btn-sm btn-block btn-grey text-center mb-3" @click="isContactModalShow = true">聯絡{{businessAccountName}}</button>
          <p v-if="isWaiting" class="text-center">{{businessAccountName}} 需於 {{ quotationTimeLimitString }} 前回覆審核結果</p>
          <!-- <p v-if="isClothSent || isClothReceived" class="text-center">寄售狀況請至 80percent 官網查詢</p> -->
        </div>

        <div class="flow-desc" v-if="isWaitingConfirm">
          <div class="flow-intro bg-white">
            <p class="mb-3">需於 {{ confirmTimeLimitString }} 前回覆</p>
            <p class="mb-3">
              確認審核結果後，可於活動兩日帶著符合資格的衣物至華山文創園區 ACT NOW 永續行動嘉年華 - 長腳衣櫥攤位換衣。
            </p>
            <p class="mb-3">
              如現場看到的實品狀態與照片不符合，長腳衣櫥有權拒絕參與本次換衣活動。
            </p>
            <div class="row no-gutters">
              <div class="col-auto">
                <input type="checkbox" class="mr-2" v-model="inputConfirmAgree"/>
              </div>
              <div class="col">
                <span>我同意</span>
              </div>
            </div>
          </div>
        </div>

        <!-- 這邊之後要放活動資訊 -->

        <div v-if="isWaitingSend">
          <p>
            ACT NOW 永續行動嘉年華活動資訊<br/>
            活動時間：2023/09/16-2023/09/17 10:00-17:00<br/>
            活動地點：華山文創園區 東2CD館
          </p>
        </div>
        <!-- <div class="flow-desc" v-if="isWaitingSend">
          <div class="flow-intro bg-white">
            <p>需於 {{ __dateToString(submit.sendClothTimeLimit) }} 前寄到</p>
            <p>
              請將衣物寄送表印出後，與以下 {{ submit.confirmedAmount }} 件衣物清洗並摺疊整齊，一同寄至80percent（裳樊有限公司）<br/>
              <br/>
              寄件資訊：<br/>
              <br/>
              <ul class="ml-0 pl-3">
                <li>
                  7-11店到店
                  <ul class="pl-3">
                    <li>
                      台北市大安區 [鑫泰門市]
                    </li>
                    <li>
                      店號：232971
                    </li>
                    <li>
                      收件人：童苡寧/0983621761
                    </li>
                  </ul>
                </li>

                <li>
                  郵寄[請指定平日到貨]
                  <ul class="pl-3">
                    <li>
                      地址：台北市大安區泰順街60巷10號7樓
                    </li>
                    <li>
                      裳樊有限公司 童苡寧 收 / 0983621761
                    </li>
                  </ul>
                </li>

                <li>
                  親自送至工作室[限平日工作日10:00-17:00] 
                  <ul class="pl-3">
                    <li>
                      地址：台北市大安區泰順街60巷10號7樓
                    </li>
                    <li>
                      聯絡電話:0983621761
                    </li>
                    <li>
                      請在快抵達時用電話通知或按樓下標註80percent的電鈴，人員會開門讓您上來把衣物送至工作室內
                    </li>
                  </ul>
                </li>
              </ul>
            </p>
          </div>
        </div>

        <div class="flow-desc" v-if="isWaitingSend">
          <div class="flow-intro">
            <h3>衣物寄送表</h3>
          </div>

          <div class="flow-intro" v-if="!ibonUploaded">
            <p class="mb-3">請至 email 收取衣物寄送表或點選以下按鈕，我們會將衣物寄送表寄送至 ibon，可在期限內於 ibon 完成印刷。</p>
            <button class="btn btn-sm btn-block btn-grey text-center" @click="sendIbonUpload">送至 ibon 印刷</button>

          </div>

          <div class="flow-intro" v-if="ibonUploaded">
            <div class="row no-gutters" style="font-size: 10px;">
              <div class="col-6">
                <img :src="'data:image/png;base64,' + submit.ibonQRCode" class="w-100"/>
              </div>

              <div class="col-6">
                <p class="mb-0">取件編號：{{ submit.ibonPincode }}</p>
                <p class="mb-0">列印期限：{{ submit.ibonDeadLine }}</p>
                <p class="mb-0">
                  列印方式說明：<br/>
                  憑 QR Code 可於全台 7-ELEVEN 門市內 ibon 機台，點選螢幕左上方「代碼輸入」>「輸入取件編號」或「掃描 QR Code」，完成操作輕鬆列印文件。
                </p>
              </div>
            </div>
            <p>本表也有寄送至您填寫的 email。若列印期限過期，可至 Email 收取衣物寄送表</p>
          </div>

          
        </div> -->
        
      </div>

      <div v-if="showedReviews.length > 0">
        <div v-for="(item, index) in showedReviews" :key="item.cloth.serial" class="select-cloth-item">
          <DeliverySubmitReview :review="item" :submit-status="submit.status" @updatePrice="updateReviewSellPrice" @delete="removeReview(index)" @updateCloth="updateReviewCloth(index, $event)" @toggleAgree="toggleReviewAgree"></DeliverySubmitReview>
        </div>
      </div>

      <div class="bottom-btn-div" v-if="isSubmitting && reviews.length > 0">
        <button class="bottom-btn" :disabled="!inputSendClothDataValid" @click="sendClicked">送出衣物資料</button>
      </div>

      <div class="bottom-btn-div" v-if="isWaitingConfirm">
        <button class="bottom-btn" :disabled="!inputConfirmAgree || finalSendClothCount === 0" @click="isAgreeConfirmModalShow = true">確認審核結果</button>
      </div>

      <!-- 暫時關閉後續動作 -->
      <!-- <div class="bottom-btn-div" v-if="isWaitingSend" style="background-color: #eee; padding: .4rem .5rem;">
        <div class="row no-gutters">
          <div class="col-7 pr-2">
            <v-date-picker class="" v-model="inputSendDate">
              <template v-slot="{ inputValue, togglePopover }">
                <div class="text-center py-2 border rounded bg-white" @click="togglePopover()">
                  <img :src="require('@/assets/nav_icons/tool_calendar.png')" style="height: 1.5rem; position: absolute; left: .5rem; top: 50%; transform: translate(0, -50%);" />
                  <span>{{ inputValue }}</span>
                </div>
              </template>
            </v-date-picker>
          </div>

          <div class="col">
            <div class="text-center py-2 border rounded bg-dark text-white" @click="isSendClotheModalShow = true">
              寄出回報
            </div>
          </div>
        </div>
        
      </div> -->
      
      <transition name="fade">
        <ClothPicker v-if="isClothPickerShow" @back="closeClothPicker" @ok="appendCloths" :except="selectClothSerials" :limit="canPickAmount"></ClothPicker>
      </transition>
      
      <transition name="fade">
        <WcCommonModal title="確定離開" v-if="isConfirmModalShow" @cancel="isConfirmModalShow = false" @ok="sendConfirm" hide-header>
          <div class="text-center">
            送出衣物資料<br/>
            送出後，不可再編輯衣物資料
          </div>
        </WcCommonModal>
      </transition>

      <transition name="fade">
        <WcCommonModal :title="`聯絡 ${businessAccountName}`" v-if="isContactModalShow" @ok="isContactModalShow = false" hide-header hide-cancel>
          <div class="">
            聯絡 {{businessAccountName}} <br/>
            <br/>
            若有訂單問題歡迎聯絡 {{ businessAccountName }}<br/>
            客服電話 ：02-7752-2510<br/>
            客服時間 / 一至五（除國定假日） 10:00-17:00<br/>
            客服 LINE ID：@walkingcloset<br/>
            <br/>
            統編 ：82975471<br/>
            人人共創數位科技有限公司
          </div>
        </WcCommonModal>
      </transition>

      <transition name="fade">
        <WcCommonModal v-if="isAgreeConfirmModalShow" @ok="sendConfirmConfirm" @cancel="isAgreeConfirmModalShow = false" hide-header>
          <div class="">
            <p class="text-center mb-4">確認審核結果</p>
            <p class="text-center mb-3">確認審核結果後，可於活動兩日帶著符合資格的衣物至華山文創園區 ACT NOW 永續行動嘉年華 - 長腳衣櫥攤位換衣。</p>
            <p class="text-center mb-3">如現場看到的實品狀態與照片不符合，長腳衣櫥有權拒絕參與本次換衣活動。</p>
            <!-- <p class="text-center">確認委託 {{ finalSendClothCount }} 件衣物寄售嗎？</p> -->
          </div>
        </WcCommonModal>
      </transition>

      <transition name="fade">
        <WcCommonModal title="選擇寄送方式" v-if="isSendClotheModalShow" @cancel="isSendClotheModalShow = false" @ok="sendClothSendNotice" hide-header>
          <div>
            <p class="text-center mb-3">寄出回報</p>
            <p class="mb-2">已於 {{ inputSendDateStr }} 寄出</p>
            <p>寄出方式</p>
            <div>
              <input type="radio" :value="0" v-model="inputSendMethod"/> 7-11店到店
            </div>
            <div>
              <input type="radio" :value="1" v-model="inputSendMethod"/> 郵寄
            </div>
            <div>
              <input type="radio" :value="2" v-model="inputSendMethod"/> 親送
            </div>
          </div>
        </WcCommonModal>
      </transition>
    </div>
  </FullScreenModal>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import FullScreenModal from '@/components/FullScreenModal.vue';
import WcCommonModal from '@/components/commons/WcCommonModal.vue';
import ClothPicker from '@/components/ClothPicker.vue';
import DeliverySubmitReview from './DeliverySubmitReview.vue';
import clothReview from '@/dataModel/clothReview.js'
import moment from 'moment';
import '@/assets/css/buttons.css';
import '@/assets/css/form-inputs.css';

export default {
  name: 'DeliverySubmitModal',
  data() {
    return {
      initBusyName: 'READSUBMITBUSY',
      saveBusyName: 'SENDSUBMITBUSY',
      init: false,

      submit: {},
      reviews: [],
      clothPriceRecords: [],

      inputConfirmAgree: false,
      
      inputSendDate: new Date(),
      inputSendMethod: -1,

      isConfirmModalShow: false,
      isClothPickerShow: false,
      isContactModalShow: false,
      isAgreeConfirmModalShow: false,
      isSendClotheModalShow: false,
    };
  },
  components: {
    FullScreenModal,
    WcCommonModal,
    ClothPicker,
    DeliverySubmitReview,
  },
  props: {
    id: {
      type: Number,
      required: false,
      default: -1,
    }
  },
  created() {
  },
  beforeDestroy() {
    this.clearComponentBusy(this.initBusyName);
    this.clearComponentBusy(this.saveBusyName);
  },
  async mounted() {
    this.appendComponentBusy(this.initBusyName);
    try {
      await this.initRead();
      this.init = true;
    } catch(e) {
      this.emitError(e);
    } finally {
      this.clearComponentBusy(this.initBusyName);
    }
  },
  computed: {
    ...mapState(['cloths']),
    businessAccountName() {
      return this.submit.businessAccount.name;
    },
    showedReviews() {
      if (this.isWaitingSend) {
        const filtered = this.reviews.filter((r) => {
          return r.pass === 1 && r.agree === 1;
        });
        return filtered;
      }
      return this.reviews;
    },
    inputName: {
      get() {
        if (this.submit.userName !== undefined && this.submit.userName !== null) {
          return this.submit.userName;
        }
        return '';
      },
      set(v) {
        this.submit.userName = v;
      },
    },
    inputEmail: {
      get() {
        if (this.submit.userEmail !== undefined) {
          return this.submit.userEmail;
        }
        return '';
      },
      set(v) {
        this.submit.userEmail = v;
      },
    },
    inputPhone: {
      get() {
        if (this.submit.userPhone !== undefined) {
          return this.submit.userPhone;
        }
        return '';
      },
      set(v) {
        this.submit.userPhone = v;
      }
    },
    submitLimitTimeStr() {
      return moment(this.submit.submitTimeLimit).format('YYYY.MM.DD HH:mm');
    },
    quotationTimeLimitString() {
      return moment(this.submit.quotationTimeLimit).format('YYYY.MM.DD HH:mm');
    },
    confirmTimeLimitString() {
      return moment(this.submit.confirmTimeLimit).format('YYYY.MM.DD HH:mm');
    },
    canPickAmount() {
      return this.submit.maxAmount - this.reviews.length;
    },
    inputEmailValid() {
      return this.inputEmail.trim().length > 0;
    },
    inputNameValid() {
      return this.inputName.trim().length > 0;
    },
    inputPhoneValid() {
      return this.inputPhone.trim().length > 0;
    },
    selectClothSerials() {
      return this.reviews.map((r) => {
        return r.cloth.serial;
      });
    },
    inputSendClothDataValid() {
      if (!this.inputEmailValid) {
        return false;
      }
      if (!this.inputNameValid) {
        return false;
      }
      if (!this.inputPhoneValid) {
        return false;
      }
      for (const r of this.reviews) {
        if (r.cloth.imgs === 0) {
          return false;
        }
        if (r.cloth.photoBack.length === 0) {
          return false;
        }
        if (r.cloth.photoBrand.length === 0) {
          return false;
        }
        if (r.cloth.photoWash.length === 0) {
          return false;
        }
        if (r.sellPrice <= 0) {
          return false;
        }
      }
      return true;
    },
    isSubmitting() {
      return this.submit.status !== undefined && this.submit.status === 0;
    },
    isWaiting() {
      return this.submit.status !== undefined && this.submit.status === 1;
    },
    isWaitingConfirm() {
      return this.submit.status !== undefined && this.submit.status === 2;
    },
    isWaitingSend() {
      return this.submit.status !== undefined && this.submit.status === 3;
    },
    isClothSent() {
      return this.submit.status !== undefined && this.submit.status === 4;
    },
    isClothReceived() {
      return this.submit.status !== undefined && this.submit.status === 5;
    },
    finalSendClothCount() {
      return this.reviews.filter((r) => {
        return r.pass !== 2 && r.agree !== 2;
      }).length;
    },
    ibonUploaded() {
      return this.submit.ibonPincode.length > 0;
    },
    inputSendDateStr() {
      return moment(this.inputSendDate).format('YYYY/MM/DD');
    },
  },
  watch: {
  },
  methods: {
    ...mapActions(['appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg']),
    async initRead() {
      const submit = await this.$store.dispatch('api/readDeliverySubmitInfoPromise', this.id);
      this.submit = Object.assign({}, this.submit, submit);
      for (const r of this.submit.reviews) {
        this.reviews.push(r);
      }
    },
    openClothPicker() {
      this.isClothPickerShow = true;
    },
    closeClothPicker() {
      this.isClothPickerShow = false;
    },
    appendCloths(clothSerials) {
      clothSerials.map((serial) => {
        const cloth = this.cloths.filter((c) => {
          return c.serial === serial;
        })
        if (cloth.length === 1) {
          const cr = Object.assign({}, clothReview());
          cr.cloth = cloth[0];
          cr.sellPrice = 0;
          cr.submitSerial = this.id;
          this.reviews.push(cr);
        }
      });
      this.closeClothPicker();
    },
    removeReview(index) {
      this.reviews.splice(index, 1);
    },
    getPriceFromRecords(serial) {
      for (const r of this.clothPriceRecords) {
        if (r.serial === serial) {
          return r.price;
        }
      }
      return 0;
    },
    sendClicked() {
      this.isConfirmModalShow = true;
    },
    async sendConfirm() {
      this.isConfirmModalShow = false;
      this.appendComponentBusy(this.saveBusyName);
      try {
        const cloths = this.reviews.map((r) => {
          return {
            serial: r.cloth.serial,
            price: r.sellPrice,
          }
        });
        const submit = await this.$store.dispatch('api/sendDeliveryClothPromise', {
          serial: this.submit.serial,
          email: this.inputEmail.trim(),
          name: this.inputName.trim(),
          phone: this.inputPhone.trim(),
          cloths,
        });
        this.$emit('updated', submit);
      } catch(e) {
        this.emitError(e);
      } finally {
        this.clearComponentBusy(this.saveBusyName);
      }
    },
    async sendConfirmConfirm() {
      this.isAgreeConfirmModalShow = false;
      this.appendComponentBusy(this.saveBusyName);
      try {
        const disagreeSerials = this.reviews.filter((r) => {
          return r.pass === 1 && r.agree === 2;
        }).map((r) => {
          return r.serial;
        });
        const submit = await this.$store.dispatch('api/sendDeliveryConfirmPromise', {
          serial: this.submit.serial,
          disagreeSerials,
        });
        this.$emit('updated', submit);
      } catch(e) {
        this.emitError(e);
      } finally {
        this.clearComponentBusy(this.saveBusyName);
      }
    },
    async sendIbonUpload() {
      this.appendComponentBusy(this.saveBusyName);
      try {
        const submit = await this.$store.dispatch('api/sendDeliveryIconUploadPromise', {
          serial: this.submit.serial
        });
        this.submit.ibonPincode = submit.ibonPincode;
        this.submit.ibonDeadLine = submit.ibonDeadLine;
        this.submit.ibonQRCode = submit.ibonQRCode;
      } catch(e) {
        this.emitError(e);
      } finally {
        this.clearComponentBusy(this.saveBusyName);
      }
    },
    async sendClothSendNotice() {
      if (this.inputSendMethod === -1) {
        alert('請選擇寄送方式');
        return;
      }
      this.appendComponentBusy(this.saveBusyName);
      try {
        const submit = await this.$store.dispatch('api/sendDeliveryClothSendNoticePromise', {
          serial: this.submit.serial,
          sendDate: this.inputSendDateStr,
          sendMethod: this.inputSendMethod
        });
        this.$emit('updated', submit);
      } catch(e) {
        this.emitError(e);
      } finally {
        this.clearComponentBusy(this.saveBusyName);
      }
    },
    backClicked() {
      this.$emit('close');
    },
    buyFinish() {
      this.$emit('buy');
    },
    updateReviewSellPrice(data) {
      const {cloth, price} = data;
      for (const review of this.reviews) {
        if (review.cloth.serial === cloth.serial) {
          review.sellPrice = price;
          return;
        }
      }
    },
    updatePriceRecord(data) {
      const {cloth, price} = data;
      for (let i=0;i<this.clothPriceRecords.length;i++) {
        const r = this.clothPriceRecords[i];
        if (r.serial === cloth.serial) {
          this.clothPriceRecords[i].price = price;
          return;
        }
      }
      this.clothPriceRecords.push({
        serial: cloth.serial,
        price,
      });
    },
    updateReviewCloth(index, cloth) {
      console.log(cloth);
      this.reviews[index].cloth = Object.assign({}, this.reviews[index].cloth, cloth);
    },
    toggleReviewAgree(r) {
      for (let i = 0; i < this.reviews.length; i++) {
        const review = this.reviews[i];
        if (review.serial === r.serial) {
          if (review.pass !== 2) {
            review.agree = (review.agree === 0 ? 2 : 0);
            return;
          }
        }
      }
    },
    __dateToString(d) {
      return moment(d).format('YYYY.MM.DD HH:mm');
    },
  }
}
</script>

<style scoped>
  .delivery-submit {
  }

  .flow-desc {
    margin-bottom: 1rem;
  }

  .flow-desc .flow-intro {
    background-color: #F8F8F8;
    padding: .8rem 1.2rem;
    color: #8d8d8d;
  }

  .flow-desc .flow-intro:not(:nth-child(1)) {
    background-color: #FFF;
  }

  .flow-desc .flow-intro>h3 {
    font-size: 1.2rem;
    font-weight: 1000;
    margin: 0;
    color: #4D4D4D;
  }

  .flow-desc .flow-intro>p {
    margin: 0;
  }

  .text-orange {
    color: var(--main-orange);
  }

  .btn-select {
    background-color: var(--main-yellow);
    /* background-color: #EEEEEE; */
    color: #4D4D4D;
  }

  .btn-grey {
    background-color: #EEEEEE;
    color: #444;
  }

  .select-cloth-item {
    border-bottom: solid 1px #CCC;
  }

  .select-cloth-item:first-child {
    border-top: solid 1px #CCC;
  }

  .bottom-btn-div {
    margin-top: .5rem;
    position: sticky;
    left: 0;
    bottom: 0;
  }

  .bottom-btn {
    background-color: #4D4D4D;
    color: #fff;
    border: none;
    display: block;
    text-align: center;
    width: 100%;
    padding: 1rem;
  }
  
  .bottom-btn:disabled {
    /* opacity: .6; */
    background-color: #898989;
  }

  .wc-form-dom.hilight {
    outline: solid 2px #f00;
  }
</style>
