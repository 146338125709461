export default () => {
  return {
    serial: -1,
    create_at: new Date().toISOString(),
    updated_at: new Date().toISOString(),
    available: true,
    clothSerial: -1,  // 對應的衣服單品編號
    pass: 0,      // 衣服是否通過(0: 未設定, 1: 通過, 2: 不通過)
    rejectReason: '',
    sellPrice: -1,    // 預計販售價錢
    finalSellPrice: -1,  // 最終決定販售價錢
    agree: 0,     // 使用者已同意審核結果(0: 未設定, 1: 同意, 2: 不同意)
    status: 0,        // 狀態(0: 審核中, 1: 待回覆, 2: 待收件, 3: 已收件, 4: 已退回)
    cloth: {},        //
    submitSerial: -1, // 所屬申請編號
  };
};
